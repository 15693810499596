import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2em;
  position: fixed;
  background: white;
  z-index: 100;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

const PatientTitle = styled.h2`
  font-size: 25px;
  color: #4e2056;
  font-weight: bold;
  margin-bottom: 10px;
`;

const PatientDataList = styled.ul`
  list-style-type: none;

  li, p {
    display: inline;
    font-size: 16px;
  }

  li {
    margin-right: 20px;
  }

  span {
    color: #4e2056;
    font-weight: bold;
    margin-right: 5px;
  }
`;

const formatDate = (dob) => {
  if (!dob) return '--';
  const date = moment(dob);
  const formattedDate = date.format('DD-MMM-YYYY');
  const age = moment().diff(date, 'years');

  return `${formattedDate} (${age} years old)`;
}

export const PatientBanner = ({ id, participant }) => {

  const [dateOfBirth, setDateOfBirth] = useState('');
  const [contactNumber, setContactNumber] = useState('');

  const {
    dob,
    user: {
      login,
      firstName,
      lastName,
      email,
      phone,
      mobile
    } = {}
  } = participant || {};

  useEffect(() => {
    setDateOfBirth(formatDate(dob));

    if (phone || mobile) {
      setContactNumber(phone || mobile);
    }
  }, [dob, phone, mobile]);

  return (
    <Wrapper>
      <PatientTitle>{lastName ?? '--'}, {firstName ?? '--'}</PatientTitle>

      <PatientDataList>
        <li><p><span>Date of Birth:</span>{dateOfBirth}</p></li>
        <li><p><span>ID Number:</span>{login ?? '--'}</p></li>
        <li><p><span>Email:</span>{email ?? '--'}</p></li>
        <li><p><span>Phone:</span>{contactNumber ?? '--'}</p></li>
      </PatientDataList>
    </Wrapper>
  )
};