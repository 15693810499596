import React, { useEffect, useState } from 'react';
import ReactTable from "react-table";
import { useDispatch, useSelector } from 'react-redux';

import { participantsActions } from 'actions';
import { PatientBanner } from 'components/PatientBanner/PatientBanner';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';

export const ParticipantEncounters = props => {
  const dispatch = useDispatch();

  const pid = parseInt(props.match.params.pid);
  const encounterId = parseInt(props.match.params.id);

  const [encounters, setEncounters] = useState([]);
  const [subtitle, setSubtitle] = useState(`(Day: - Event: )`);

  const {
    participantsLoading: loading,
    summary: {
      participant,
      encounters: storedEncounters
    }
  } = useSelector(state => state.participants) || {};

  useEffect(() => {
    if (storedEncounters) {
      const {
        eventNumber,
        dayNumber,
        details
      } = storedEncounters.find(item => item.id === encounterId);

      details.sort((a, b) => { return a.order < b.order ? -1 : 1 });
      setEncounters(details);
      setSubtitle(`(Day: ${dayNumber} - Event: ${eventNumber})`);
    }

  }, [participant, storedEncounters, loading, encounterId]);

  useEffect(() => {
    if (pid) {
      dispatch(participantsActions.getSummary(pid));
    }
  }, [pid, dispatch]);

  if (loading) {
    return <p>Loading...</p>
  }

  return (
    <>
      <PatientBanner id={pid} participant={participant} />
      <PageWrapper title="Encounter Details for Service User" subtitle={subtitle} withPatientBanner>
        <ReactTable
          data={encounters}
          sortable={false}
          columns={[
            {
              Header: "Question",
              accessor: "question",
              width: 500
            },
            {
              Header: "Symptom",
              accessor: "symptom",
              width: 180
            },
            {
              Header: "Answer",
              accessor: "answer",
              width: 75,
              Cell: row => <div style={{ textAlign: "center" }} key={row.id}>{row.value}</div>
            }
          ]}
          showPagination={true}
          className="-striped"
        />
      </PageWrapper>
    </>
  )
}
