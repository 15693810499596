import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from 'assets/img/logo_transparent_medium.png';

const SideNavigationWrapper = styled.div`
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.15);
  background-color: #f8f9fa;
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  z-index: 1000;
  overflow-x: hidden;
  padding-top: 20px;
`;

const NavigationLink = styled.div`
  &.active {
    background: #08848f;
    
    p, i {
      color: white;
    }
  }

  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid transparent;
  padding: 10px;
  border-radius: 5px;

  p {
    margin: 5px;
    font-size: 14px;
    color: #434342;
    max-width: 150px;
  }

  i {
    min-width: 30px;
    font-size: 20px;
    margin-right: 10px;
    color: #434342;
  }

  &:hover:not(.active) {
    p, i {
      color: black;
    }
  }
`;

const Logo = styled.div`
  text-align: center;
   img {
    width: 90%;
   }
`;

const FooterContent = styled.div`
  border-top: 1px solid #e9ecef;
  padding: 10px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const isNavItemActive = (path) => window.location.pathname.includes(path);

export const SideNavigation = (props) => {
  const {
    routes,
    minimizeSidebar,
    logout
  } = props;

  const loggedIn = useSelector(state => state.authentication.loggedIn);

  const [isMinimised] = useState(false);
  const [sidebarRoutes, setSidebarRoutes] = useState([]);
  const [username, setUsername] = useState('');

  const handleLogout = useCallback(() => {
    logout();
    window.location.href = window.BASE_URL;
  }, [logout]);

  useEffect(() => {
    if (loggedIn === false) {
      return handleLogout();
    }

    const user = window.localStorage.getItem('login');
    setUsername(user);
  }, [loggedIn, handleLogout]);

  useEffect(() => {
    if (isMinimised) {
      minimizeSidebar();
    }
  }, [isMinimised, minimizeSidebar]);

  useEffect(() => {
    const userRole = window.localStorage.getItem('role');
    const sidebarRoutes = routes
      .filter((route) => route.visible === true)
      .filter((route) => route.role.includes(userRole) === true);

    setSidebarRoutes(sidebarRoutes);
  }, [routes]);

  const renderNavigationLinkItemContent = (route, index) => {
    const navItemIsActive = isNavItemActive(route.layout + route.path);
    const icon = `fa fa-${route.icon}`;

    return (
      <NavigationLink key={route.name} className={navItemIsActive ? 'active' : ''}>
        <Link className='flex items-center' to={route.layout + route.path}>
          <i className={icon}></i>
          {!isMinimised && <p style={{ display: 'inline-block' }}>{route.name}</p>}
        </Link>
      </NavigationLink>
    )
  }

  const renderRouteLinks = () => {
    return sidebarRoutes.map((route, index) => {
      return renderNavigationLinkItemContent(route, index);
    });
  };

  return (
    <SideNavigationWrapper>
      <Logo>
        <img className="pl-[10px]" src={logo} alt="react-logo" />
      </Logo>

      <div style={{ padding: 10, marginTop: 20 }}>
        {renderRouteLinks()}
      </div>

      <FooterContent>
        <div>
          <p className='text-center'>{username}</p>
          <button  className='text-center underline' onClick={handleLogout} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer', textDecoration: 'underline', color: 'inherit' }}>Logout</button >
        </div>
      </FooterContent>
    </SideNavigationWrapper>
  )
};
